import React, { useState } from "react"

import Layout from "../components/layout.component"
import Seo from "../components/seo.component"
import ContactForm from "../components/contact-form.component"

import "./contact.styles.scss"

const ContactPage = ({ location }) => {
  const [mapLocation, setMapLocation] = useState("wexford")

  return (
    <Layout className="contact" pathname={location.pathname}>
      <Seo
        title="Contact Parasky Flute Studios"
        description="Contact Parasky Flute Studios to book a flute lesson in the Pittsburgh area or remotely."
      />
      <section className="contact__hero hero is-medium id-dark">
        {mapLocation === "murrysville" ? (
          <iframe
            width="100%"
            height="100%"
            loading="lazy"
            allowFullScreen
            src="https://www.google.com/maps/embed/v1/place?q=place_id:ChIJe9D-i0LBNIgRb65NlAb2pSw&key=AIzaSyCouE16V2uiS9QPBZBn0YuekuAuuzWklZQ"
            title="Murrysville Location Map"
          ></iframe>
        ) : mapLocation === "wexford" ? (
          <iframe
            width="100%"
            height="100%"
            loading="lazy"
            allowFullScreen
            src="https://www.google.com/maps/embed/v1/place?q=place_id:ChIJUXfphXiJNIgRf_s3Rn1cI2c&key=AIzaSyCouE16V2uiS9QPBZBn0YuekuAuuzWklZQ"
            title="Wexford Location Map"
          ></iframe>
        ) : (
          <div className="container">
            <div className="hero-body is-flex-direction-column is-justify-content-center">
              <h1 className="title is-size-2 is-size-1-desktop has-text-white is-uppercase has-text-centered">
                Contact
              </h1>
            </div>
          </div>
        )}
      </section>
      <div className="contact__tabs">
        <div className="container">
          <div className="tabs is-toggle is-fullwidth">
            <ul>
              <li className={mapLocation === "wexford" ? "is-active" : ""}>
                <a role="button" href="#" onClick={() => setMapLocation("wexford")}>Wexford&nbsp;<span className="is-hidden-mobile">Studio</span></a>
              </li>
              <li className={mapLocation === "murrysville" ? "is-active" : ""}>
                <a role="button" href="#" onClick={() => setMapLocation("murrysville")}>Murrysville&nbsp;<span className="is-hidden-mobile">Studio</span></a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <section className="contact__form">
        <div className="container">
          <div className="content is-flex is-flex-direction-column">
            <h3 className="has-text-centered has-border-bottom-info-1 is-align-self-center">
              Contact me for inquiries and lessons
            </h3>
          </div>
          <ContactForm />
        </div>
      </section>
    </Layout>
  )
}

export default ContactPage

import React, { useState } from "react"
import { FaUser, FaEnvelope, FaPhoneAlt, FaArrowRight } from "react-icons/fa"

import PrivacyPolicy from "./privacy-policy.component"

import "./contact-form.styles.scss"

const ContactForm = () => {
  const [policy, setPolicy] = useState(false)
  const [modalOpen, setModalOpen] = useState(false)
  const closeModal = () => {
    setModalOpen(false)
  }

  const handleKeyDown = ev => {
    if (ev.keyCode === 13) {
      closeModal()
    }
  }

  return (
    <form
      method="post"
      action="/success"
      netlify-honeypot="bot-field"
      data-netlify="true"
      name="Parasky Flute Studios Contact"
      className="contact-form is-75-percent-mobile-xl"
    >
      <input type="hidden" name="bot-field" />
      <input type="hidden" name="form-name" value="Parasky Flute Studios Contact" />
      <div className="field is-horizontal">
        <div className="field-body">
          <div className="field">
            <label className="label" htmlFor="student">
              Student Name
            </label>
            <p className="control is-expanded has-icons-left">
              <input
                className="input"
                type="text"
                placeholder="FirstName LastName"
                name="student"
                required
              />
              <span className="icon is-small is-left">
                <FaUser />
              </span>
            </p>
          </div>
          <div className="field">
            <label className="label" htmlFor="parent">
              Parent / Guardian Name
            </label>
            <p className="control is-expanded has-icons-left">
              <input
                className="input"
                type="text"
                placeholder="FirstName LastName"
                name="parent"
              />
              <span className="icon is-small is-left">
                <FaUser />
              </span>
            </p>
          </div>
        </div>
      </div>
      <div className="field is-horizontal">
        <div className="field-body">
          <div className="field">
            <label className="label" htmlFor="email">
              Email Address
            </label>
            <p className="control is-expanded has-icons-left">
              <input
                className="input"
                type="email"
                placeholder="email@example.com"
                name="email"
                required
              />
              <span className="icon is-small is-left">
                <FaEnvelope />
              </span>
            </p>
          </div>
          <div className="field">
            <label className="label" htmlFor="phone">
              Mobile Phone Number
            </label>
            <p className="control is-expanded has-icons-left">
              <input
                className="input"
                type="tel"
                pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                placeholder="123-456-7890"
                name="phone"
              />
              <span className="icon is-small is-left">
                <FaPhoneAlt />
              </span>
            </p>
          </div>
        </div>
      </div>
      <div className="field is-horizontal">
        <div className="field-body">
          <div className="field">
            <label className="label" htmlFor="comments">
              Comments
            </label>
            <div className="control">
              <textarea
                className="textarea"
                placeholder="Any questions or comments"
                name="comments"
              ></textarea>
            </div>
          </div>
        </div>
      </div>
      <div className="field is-horizontal pt-4">
        <div className="field-body">
          <div className="field is-flex is-align-items-center">
            <div className="control has-text-left">
              <label className="checkbox">
                <input
                  type="checkbox"
                  name="policy"
                  checked={policy}
                  onChange={() => setPolicy(!policy)}
                  required
                />{" "}
                I have read and agree to the{" "}
                <button
                  className="button is-ghost"
                  style={{ height: "auto", lineHeight: 1, padding: 0 }}
                  onClick={() => setModalOpen(true)}
                >
                  privacy policy
                </button>
              </label>
            </div>
          </div>
          <div className="field">
            <div className="control has-text-left-mobile has-text-right-tablet">
              <button className="button is-link button--send">Send <FaArrowRight /></button>
            </div>
          </div>
        </div>
      </div>
      <div
        class={`modal ${modalOpen ? "is-active" : ""}`}
        style={{ zIndex: 1000 }}
      >
        <div class="modal-background" onClick={closeModal} onKeyDown={handleKeyDown} role="presentation"></div>
        <div class="modal-card">
          <header class="modal-card-head">
            <p class="modal-card-title"></p>
            <button
              class="delete"
              aria-label="close"
              onClick={closeModal}
            ></button>
          </header>
          <section class="modal-card-body">
            <PrivacyPolicy />
          </section>
          <footer class="modal-card-foot is-justify-content-space-between">
            <button class="button" type="button" onClick={closeModal}>
              Cancel
            </button>
            <button
              class="button is-success"
              type="button"
              onClick={() => {
                setPolicy(true)
                closeModal()
              }}
            >
              Accept
            </button>
          </footer>
        </div>
      </div>
    </form>
  )
}

export default ContactForm
